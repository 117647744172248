import React, { FC, useEffect } from 'react';
import { CartItem } from '../../types';
import { useSelector } from 'react-redux';
import { rootReducer } from '../../store';
import { navigate, PageProps } from 'gatsby';
import PageLoader from '../../components/common/Loader/PageLoader';

interface Props extends PageProps {
  couponCode: string;
}

const CouponPage: FC<Props> = (props) => {
  const cartItems: CartItem[] = useSelector(
    (state: rootReducer) => state.cart.items
  );
  useEffect(() => {
    if (cartItems?.length > 0) {
      const url = location.search
        ? `/checkout/${location.search}&apply_coupon=${props.couponCode}`
        : `/checkout/?apply_coupon=${props.couponCode}`;

      navigate(url);
    } else {
      navigate('/');
    }
  }, [props, cartItems]);

  return (
    <div className="h-screen flex justify-center items-center">
      <PageLoader />
    </div>
  );
};

export default CouponPage;
